<template>
  <div id="cc-header">
    <router-link to="/home" class="logoWrapper">
      <div class="logo"></div>
      <div class="logoSplit"></div>
      <div class="logoText">Call Center System</div>
    </router-link>
    <div class="mms-header__user-info fluid">
      <div class="user-info-top">
        <!-- <div class="avatar">
          <img
            src="http://t16img.yangkeduo.com/pdd_ims/img_check/v2/FFE6E6E62F2C0320200112153044856/dff66f3dc9974919877a97c5b3c5a17a.png"
          />
        </div> -->
        <div class="user-name">
          <p class="name">
            {{ userInfo && userInfo.firstName + userInfo.lastName }}
          </p>
          <!-- <p class="id">cc123456</p> -->
        </div>
        <div class="mms-header__user-info-arrow"></div>
      </div>
      <div class="user-info-bottom">
        <div class="mms-header__userinfo-account">
          <router-link to="/accountSetting/accountInfo">
            <div class="account">
              <span class="account-red-dot"></span>
              Account
            </div>
          </router-link>
        </div>
        <!-- <div class="mms-header__userinfo-account">
                    <router-link to="/accountSetting/changePassword">
                        <div class="account">
                            <span class="account-red-dot"></span>
                            修改密码
                        </div>
                    </router-link>
                </div> -->
        <div class="mms-header__userinfo-account">
          <div class="logout" @click="logout">Logout</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useRouter } from 'vue-router'
import { mapState, useStore } from 'vuex'
export default {
  setup() {
    const {
      state: { userInfo },
      dispatch,
    } = useStore()
    const router = useRouter()
    const logout = async function () {
      await dispatch('resetToken')
      router.replace('/login')
    }
    return {
      userInfo,
      logout,
    }
  },
}
</script>

<style lang="less" scoped>
@import '../../assets/colors.less';
#cc-header {
  width: 100%;
  background-color: #393e4d;
  height: @header-height;
  min-width: 1280px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1000;
  position: fixed;
  top: 0;
  .logoWrapper {
    display: flex;
    margin-left: 10px;
    margin-right: 36px;
    align-items: center;
    .logo {
      width: 120px;
      height: 32px;
      background-image: url(http://image.matchupexpo.com/08409e0a570c4b03b879cad43e7f06ee.png);
      background-repeat: no-repeat;
      background-position: 0 0;
      margin-right: 6px;
      background-size: cover;
    }
    .logoSplit {
      width: 1px;
      height: 13px;
      background: #fff;
      opacity: 0.24;
    }
    .logoText {
      font-size: 16px;
      color: #fff;
      margin-left: 5px;
    }
  }
  .mms-header__user-info {
    cursor: pointer;
    width: 160px;
    height: @header-height;
    margin-right: 16px;
    position: relative;
    display: flex;
    align-items: center;
    &:hover {
      .user-info-bottom {
        transform: scale(1);
      }
      .user-info-top .mms-header__user-info-arrow {
        transform: rotate(180deg);
      }
    }
    .user-info-top {
      display: flex;
      align-items: center;
      .avatar {
        width: 32px;
        margin-right: 8px;
        display: flex;
        align-items: center;
        img {
          width: 32px;
          height: 32px;
          border-radius: 16px;
        }
      }
      .user-name {
        width: 100px;
        p {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          margin: 0;
        }
        .name {
          color: #fff;
          // margin-bottom: 5px;
        }
        .id {
          color: hsla(0, 0%, 100%, 0.64);
        }
      }
      .mms-header__user-info-arrow {
        width: 12px;
        height: 12px;
        display: inline-block;
        margin-left: 4px;
        background: url(../../assets/arrow-down.png) no-repeat;
        background-size: contain;
        transition: transform 0.2s 0.2s, margin-top 0.2s 0.2s;
        transform: rotate(0deg);
        font-size: 12px;
        transform-origin: center;
      }
    }
    .user-info-bottom {
      transition: transform 0.2s 0.2s;
      transform: scale(0);
      transform-origin: top;
      position: absolute;
      left: 0;
      top: @header-height;
      width: 152px;
      background-color: #fff;
      z-index: 999;
      font-size: 14px;
      text-align: center;
      cursor: pointer;
      box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.4);
      &:hover {
        .user-info-bottom {
          transform: scale(1);
        }
      }
      .mms-header__userinfo-account {
        padding: 0 16px;
        color: rgba(0, 0, 0, 0.8);
        a {
          color: rgba(0, 0, 0, 0.8);
        }
        &:hover {
          background-color: #f0f7ff;
          color: #407cff;
          a {
            color: #407cff;
          }
        }
      }
      .account {
        width: 90%;
        height: 40px;
        margin: 0 auto;
        line-height: 40px;
        border-bottom: 1px solid #f5f5f5;
        position: relative;
      }
      .logout {
        height: 40px;
        line-height: 40px;
        cursor: pointer;
      }
    }
  }
}
</style>
