<template>
    <div id="layout">
        <Header/>
        <router-view></router-view>
    </div>
</template>

<script>
import Header from "./Header";
    export default {
        name: '',
        data() {
            return {
                
            }
        },
        components:{
            Header
        }
    }
</script>

<style lang="less" scoped>
    #layout{
        display: flex;
        height: 100vh;
        flex-direction: column;
        background-color: #eff0f4;
        padding-top: 56px;
        overflow: auto;
        .layout-contain{
            display: flex;
        }
    }
</style>